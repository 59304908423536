import revive_payload_client_7sQ0sth3Jm from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_xgrdxx5uk2fxky6oltzcichmey/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_69SVNixS8e from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_xgrdxx5uk2fxky6oltzcichmey/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_p9PGoM2Yzb from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_xgrdxx5uk2fxky6oltzcichmey/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_YKNrpD4j6g from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_xgrdxx5uk2fxky6oltzcichmey/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_V9nodhCJzj from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_xgrdxx5uk2fxky6oltzcichmey/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_EWnixRpva6 from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_xgrdxx5uk2fxky6oltzcichmey/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_Wo9ATmT7bi from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_xgrdxx5uk2fxky6oltzcichmey/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_RbPfzFQTrZ from "/usr/src/app/node_modules/.pnpm/@pinia+nuxt@0.4.11_magicast@0.3.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.11_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/usr/src/app/.nuxt/components.plugin.mjs";
import prefetch_client_C8iyzjIDeH from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_xgrdxx5uk2fxky6oltzcichmey/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_IFjj03WcLP from "/usr/src/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.24.0_vue@3.5.11_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_2UrJpQgdI3 from "/usr/src/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.24.0_vue@3.5.11_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_client_A7pgfKMnUM from "/usr/src/app/node_modules/.pnpm/nuxt-gtag@2.1.0_magicast@0.3.5_rollup@4.24.0/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import i18n_VfGcjrvSkj from "/usr/src/app/plugins/i18n.ts";
import sentry_3AyO8nEfhE from "/usr/src/app/plugins/sentry.ts";
export default [
  revive_payload_client_7sQ0sth3Jm,
  unhead_69SVNixS8e,
  router_p9PGoM2Yzb,
  payload_client_YKNrpD4j6g,
  navigation_repaint_client_V9nodhCJzj,
  check_outdated_build_client_EWnixRpva6,
  chunk_reload_client_Wo9ATmT7bi,
  plugin_vue3_RbPfzFQTrZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_C8iyzjIDeH,
  switch_locale_path_ssr_IFjj03WcLP,
  i18n_2UrJpQgdI3,
  plugin_client_A7pgfKMnUM,
  i18n_VfGcjrvSkj,
  sentry_3AyO8nEfhE
]