import { default as editXWLqBDVoZcMeta } from "/usr/src/app/pages/customer/[customerId]/context/[contextId]/edit.vue?macro=true";
import { default as createaYle3GBfH7Meta } from "/usr/src/app/pages/customer/[customerId]/context/create.vue?macro=true";
import { default as indexAY8IRtIST9Meta } from "/usr/src/app/pages/customer/[customerId]/context/index.vue?macro=true";
import { default as editsMYch7BRAaMeta } from "/usr/src/app/pages/customer/[customerId]/edit.vue?macro=true";
import { default as home7S07x4FlFVMeta } from "/usr/src/app/pages/customer/[customerId]/home.vue?macro=true";
import { default as edit8JHqIS6hZ1Meta } from "/usr/src/app/pages/customer/[customerId]/period/[periodId]/edit.vue?macro=true";
import { default as createbOPIJ2nlfMMeta } from "/usr/src/app/pages/customer/[customerId]/period/create.vue?macro=true";
import { default as indexPYqD9CTrUhMeta } from "/usr/src/app/pages/customer/[customerId]/period/index.vue?macro=true";
import { default as editK214Svbzq6Meta } from "/usr/src/app/pages/customer/[customerId]/report/[reportId]/edit.vue?macro=true";
import { default as createqvuUWCW0O8Meta } from "/usr/src/app/pages/customer/[customerId]/report/create.vue?macro=true";
import { default as index99ONt2CeljMeta } from "/usr/src/app/pages/customer/[customerId]/report/index.vue?macro=true";
import { default as createEDGBo9ljpdMeta } from "/usr/src/app/pages/customer/create.vue?macro=true";
import { default as indexxwwyfpv7B8Meta } from "/usr/src/app/pages/customer/index.vue?macro=true";
import { default as homeaogcECheA9Meta } from "/usr/src/app/pages/home.vue?macro=true";
import { default as indexMqL0VaR3piMeta } from "/usr/src/app/pages/login/index.vue?macro=true";
import { default as indexuq0ptdCDHMMeta } from "/usr/src/app/pages/org/[orgId]/builder/index.vue?macro=true";
import { default as editGOARVqxqaeMeta } from "/usr/src/app/pages/org/[orgId]/builder/output/[outputId]/edit.vue?macro=true";
import { default as createBE2E1aJkuHMeta } from "/usr/src/app/pages/org/[orgId]/builder/output/create.vue?macro=true";
import { default as indexAAyJHCrMW4Meta } from "/usr/src/app/pages/org/[orgId]/builder/output/index.vue?macro=true";
import { default as editSUhGvcSmiFMeta } from "/usr/src/app/pages/org/[orgId]/builder/path/[pathId]/edit.vue?macro=true";
import { default as edit1wMMz39FrIMeta } from "/usr/src/app/pages/org/[orgId]/builder/path/[pathId]/module/[moduleId]/edit.vue?macro=true";
import { default as editklTrazbbS5Meta } from "/usr/src/app/pages/org/[orgId]/builder/path/[pathId]/module/[moduleId]/page/[pageId]/edit.vue?macro=true";
import { default as editBy3i8rNIYzMeta } from "/usr/src/app/pages/org/[orgId]/builder/path/[pathId]/module/[moduleId]/page/[pageId]/widget/[widgetId]/edit.vue?macro=true";
import { default as _91widgetType_93WgRUT0siAfMeta } from "/usr/src/app/pages/org/[orgId]/builder/path/[pathId]/module/[moduleId]/page/[pageId]/widget/create/[widgetType].vue?macro=true";
import { default as listt0NLU3jHwLMeta } from "/usr/src/app/pages/org/[orgId]/builder/path/[pathId]/module/[moduleId]/page/list.vue?macro=true";
import { default as list4fBJ97ktEmMeta } from "/usr/src/app/pages/org/[orgId]/builder/path/[pathId]/module/list.vue?macro=true";
import { default as createhI9wS27KqEMeta } from "/usr/src/app/pages/org/[orgId]/builder/path/create.vue?macro=true";
import { default as indexrmTgOGvUnQMeta } from "/usr/src/app/pages/org/[orgId]/builder/path/index.vue?macro=true";
import { default as index80hjd6ASBRMeta } from "/usr/src/app/pages/org/[orgId]/cluster/[clusterId]/ai-evaluation/[projectId]/index.vue?macro=true";
import { default as index_46clientiMiC5oV0LXMeta } from "/usr/src/app/pages/org/[orgId]/cluster/[clusterId]/ai-evaluation/index.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_xgrdxx5uk2fxky6oltzcichmey/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as editIaRaxwYHp6Meta } from "/usr/src/app/pages/org/[orgId]/cluster/[clusterId]/edit.vue?macro=true";
import { default as editcMSXlHvqLSMeta } from "/usr/src/app/pages/org/[orgId]/cluster/categories/[categoryId]/edit.vue?macro=true";
import { default as createbfCAOgodVuMeta } from "/usr/src/app/pages/org/[orgId]/cluster/categories/create.vue?macro=true";
import { default as indexjFSspsvqjeMeta } from "/usr/src/app/pages/org/[orgId]/cluster/categories/index.vue?macro=true";
import { default as createbI1IJBHQSxMeta } from "/usr/src/app/pages/org/[orgId]/cluster/create.vue?macro=true";
import { default as indexDntc1NhH3MMeta } from "/usr/src/app/pages/org/[orgId]/cluster/index.vue?macro=true";
import { default as editNQxt6PXImZMeta } from "/usr/src/app/pages/org/[orgId]/edit.vue?macro=true";
import { default as edithUZbjfJGHuMeta } from "/usr/src/app/pages/org/[orgId]/evaluation/[evaluationId]/edit.vue?macro=true";
import { default as create2rwtlBAwzJMeta } from "/usr/src/app/pages/org/[orgId]/evaluation/create.vue?macro=true";
import { default as indexCxR8mtWfcoMeta } from "/usr/src/app/pages/org/[orgId]/evaluation/index.vue?macro=true";
import { default as indexwD09jQLrN6Meta } from "/usr/src/app/pages/org/[orgId]/export/index.vue?macro=true";
import { default as homeoNk4VAy7cqMeta } from "/usr/src/app/pages/org/[orgId]/home.vue?macro=true";
import { default as edit3JAFhhvJkQMeta } from "/usr/src/app/pages/org/[orgId]/project/[projectId]/edit.vue?macro=true";
import { default as infoNAFP06zvMUMeta } from "/usr/src/app/pages/org/[orgId]/project/[projectId]/info.vue?macro=true";
import { default as indexXMNanknYUAMeta } from "/usr/src/app/pages/org/[orgId]/project/index.vue?macro=true";
import { default as edit1JjAu1HHzhMeta } from "/usr/src/app/pages/org/[orgId]/user/[userId]/edit.vue?macro=true";
import { default as infonhdu4xF0eIMeta } from "/usr/src/app/pages/org/[orgId]/user/[userId]/info.vue?macro=true";
import { default as index7mXU2ApfXMMeta } from "/usr/src/app/pages/org/[orgId]/user/index.vue?macro=true";
import { default as indexazRby34V2JMeta } from "/usr/src/app/pages/org/index.vue?macro=true";
import { default as classAXlTwEqX1XMeta } from "/usr/src/app/pages/reporting/[reportingId]/class.vue?macro=true";
import { default as indexwXduZLa4SaMeta } from "/usr/src/app/pages/reporting/[reportingId]/index.vue?macro=true";
import { default as project8cHU0OTXNiMeta } from "/usr/src/app/pages/reporting/[reportingId]/project.vue?macro=true";
import { default as studentuBN5vVbrA2Meta } from "/usr/src/app/pages/reporting/[reportingId]/student.vue?macro=true";
import { default as teacherH2pbz1j3AgMeta } from "/usr/src/app/pages/reporting/[reportingId]/teacher.vue?macro=true";
import { default as editKdNTK2ZEUNMeta } from "/usr/src/app/pages/user/[userId]/edit.vue?macro=true";
import { default as info6XHwXaGi62Meta } from "/usr/src/app/pages/user/[userId]/info.vue?macro=true";
import { default as index9wmPuxyvMJMeta } from "/usr/src/app/pages/user/index.vue?macro=true";
import { default as component_45stubnkSpTnJSJIMeta } from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_xgrdxx5uk2fxky6oltzcichmey/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubnkSpTnJSJI } from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_xgrdxx5uk2fxky6oltzcichmey/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "customer-customerId-context-contextId-edit",
    path: "/customer/:customerId()/context/:contextId()/edit",
    meta: editXWLqBDVoZcMeta || {},
    component: () => import("/usr/src/app/pages/customer/[customerId]/context/[contextId]/edit.vue")
  },
  {
    name: "customer-customerId-context-create",
    path: "/customer/:customerId()/context/create",
    meta: createaYle3GBfH7Meta || {},
    component: () => import("/usr/src/app/pages/customer/[customerId]/context/create.vue")
  },
  {
    name: "customer-customerId-context",
    path: "/customer/:customerId()/context",
    meta: indexAY8IRtIST9Meta || {},
    component: () => import("/usr/src/app/pages/customer/[customerId]/context/index.vue")
  },
  {
    name: "customer-customerId-edit",
    path: "/customer/:customerId()/edit",
    meta: editsMYch7BRAaMeta || {},
    component: () => import("/usr/src/app/pages/customer/[customerId]/edit.vue")
  },
  {
    name: "customer-customerId-home",
    path: "/customer/:customerId()/home",
    meta: home7S07x4FlFVMeta || {},
    component: () => import("/usr/src/app/pages/customer/[customerId]/home.vue")
  },
  {
    name: "customer-customerId-period-periodId-edit",
    path: "/customer/:customerId()/period/:periodId()/edit",
    meta: edit8JHqIS6hZ1Meta || {},
    component: () => import("/usr/src/app/pages/customer/[customerId]/period/[periodId]/edit.vue")
  },
  {
    name: "customer-customerId-period-create",
    path: "/customer/:customerId()/period/create",
    meta: createbOPIJ2nlfMMeta || {},
    component: () => import("/usr/src/app/pages/customer/[customerId]/period/create.vue")
  },
  {
    name: "customer-customerId-period",
    path: "/customer/:customerId()/period",
    meta: indexPYqD9CTrUhMeta || {},
    component: () => import("/usr/src/app/pages/customer/[customerId]/period/index.vue")
  },
  {
    name: "customer-customerId-report-reportId-edit",
    path: "/customer/:customerId()/report/:reportId()/edit",
    meta: editK214Svbzq6Meta || {},
    component: () => import("/usr/src/app/pages/customer/[customerId]/report/[reportId]/edit.vue")
  },
  {
    name: "customer-customerId-report-create",
    path: "/customer/:customerId()/report/create",
    meta: createqvuUWCW0O8Meta || {},
    component: () => import("/usr/src/app/pages/customer/[customerId]/report/create.vue")
  },
  {
    name: "customer-customerId-report",
    path: "/customer/:customerId()/report",
    meta: index99ONt2CeljMeta || {},
    component: () => import("/usr/src/app/pages/customer/[customerId]/report/index.vue")
  },
  {
    name: "customer-create",
    path: "/customer/create",
    meta: createEDGBo9ljpdMeta || {},
    component: () => import("/usr/src/app/pages/customer/create.vue")
  },
  {
    name: "customer",
    path: "/customer",
    meta: indexxwwyfpv7B8Meta || {},
    component: () => import("/usr/src/app/pages/customer/index.vue")
  },
  {
    name: "home",
    path: "/home",
    meta: homeaogcECheA9Meta || {},
    component: () => import("/usr/src/app/pages/home.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: indexMqL0VaR3piMeta || {},
    component: () => import("/usr/src/app/pages/login/index.vue")
  },
  {
    name: "org-orgId-builder",
    path: "/org/:orgId()/builder",
    meta: indexuq0ptdCDHMMeta || {},
    component: () => import("/usr/src/app/pages/org/[orgId]/builder/index.vue")
  },
  {
    name: "org-orgId-builder-output-outputId-edit",
    path: "/org/:orgId()/builder/output/:outputId()/edit",
    meta: editGOARVqxqaeMeta || {},
    component: () => import("/usr/src/app/pages/org/[orgId]/builder/output/[outputId]/edit.vue")
  },
  {
    name: "org-orgId-builder-output-create",
    path: "/org/:orgId()/builder/output/create",
    meta: createBE2E1aJkuHMeta || {},
    component: () => import("/usr/src/app/pages/org/[orgId]/builder/output/create.vue")
  },
  {
    name: "org-orgId-builder-output",
    path: "/org/:orgId()/builder/output",
    meta: indexAAyJHCrMW4Meta || {},
    component: () => import("/usr/src/app/pages/org/[orgId]/builder/output/index.vue")
  },
  {
    name: "org-orgId-builder-path-pathId-edit",
    path: "/org/:orgId()/builder/path/:pathId()/edit",
    meta: editSUhGvcSmiFMeta || {},
    component: () => import("/usr/src/app/pages/org/[orgId]/builder/path/[pathId]/edit.vue")
  },
  {
    name: "org-orgId-builder-path-pathId-module-moduleId-edit",
    path: "/org/:orgId()/builder/path/:pathId()/module/:moduleId()/edit",
    meta: edit1wMMz39FrIMeta || {},
    component: () => import("/usr/src/app/pages/org/[orgId]/builder/path/[pathId]/module/[moduleId]/edit.vue")
  },
  {
    name: "org-orgId-builder-path-pathId-module-moduleId-page-pageId-edit",
    path: "/org/:orgId()/builder/path/:pathId()/module/:moduleId()/page/:pageId()/edit",
    meta: editklTrazbbS5Meta || {},
    component: () => import("/usr/src/app/pages/org/[orgId]/builder/path/[pathId]/module/[moduleId]/page/[pageId]/edit.vue")
  },
  {
    name: "org-orgId-builder-path-pathId-module-moduleId-page-pageId-widget-widgetId-edit",
    path: "/org/:orgId()/builder/path/:pathId()/module/:moduleId()/page/:pageId()/widget/:widgetId()/edit",
    component: () => import("/usr/src/app/pages/org/[orgId]/builder/path/[pathId]/module/[moduleId]/page/[pageId]/widget/[widgetId]/edit.vue")
  },
  {
    name: "org-orgId-builder-path-pathId-module-moduleId-page-pageId-widget-create-widgetType",
    path: "/org/:orgId()/builder/path/:pathId()/module/:moduleId()/page/:pageId()/widget/create/:widgetType()",
    component: () => import("/usr/src/app/pages/org/[orgId]/builder/path/[pathId]/module/[moduleId]/page/[pageId]/widget/create/[widgetType].vue")
  },
  {
    name: "org-orgId-builder-path-pathId-module-moduleId-page-list",
    path: "/org/:orgId()/builder/path/:pathId()/module/:moduleId()/page/list",
    meta: listt0NLU3jHwLMeta || {},
    component: () => import("/usr/src/app/pages/org/[orgId]/builder/path/[pathId]/module/[moduleId]/page/list.vue")
  },
  {
    name: "org-orgId-builder-path-pathId-module-list",
    path: "/org/:orgId()/builder/path/:pathId()/module/list",
    meta: list4fBJ97ktEmMeta || {},
    component: () => import("/usr/src/app/pages/org/[orgId]/builder/path/[pathId]/module/list.vue")
  },
  {
    name: "org-orgId-builder-path-create",
    path: "/org/:orgId()/builder/path/create",
    meta: createhI9wS27KqEMeta || {},
    component: () => import("/usr/src/app/pages/org/[orgId]/builder/path/create.vue")
  },
  {
    name: "org-orgId-builder-path",
    path: "/org/:orgId()/builder/path",
    meta: indexrmTgOGvUnQMeta || {},
    component: () => import("/usr/src/app/pages/org/[orgId]/builder/path/index.vue")
  },
  {
    name: "org-orgId-cluster-clusterId-ai-evaluation-projectId",
    path: "/org/:orgId()/cluster/:clusterId()/ai-evaluation/:projectId()",
    meta: index80hjd6ASBRMeta || {},
    component: () => import("/usr/src/app/pages/org/[orgId]/cluster/[clusterId]/ai-evaluation/[projectId]/index.vue")
  },
  {
    name: "org-orgId-cluster-clusterId-ai-evaluation",
    path: "/org/:orgId()/cluster/:clusterId()/ai-evaluation",
    meta: index_46clientiMiC5oV0LXMeta || {},
    component: () => createClientPage(() => import("/usr/src/app/pages/org/[orgId]/cluster/[clusterId]/ai-evaluation/index.client.vue"))
  },
  {
    name: "org-orgId-cluster-clusterId-edit",
    path: "/org/:orgId()/cluster/:clusterId()/edit",
    meta: editIaRaxwYHp6Meta || {},
    component: () => import("/usr/src/app/pages/org/[orgId]/cluster/[clusterId]/edit.vue")
  },
  {
    name: "org-orgId-cluster-categories-categoryId-edit",
    path: "/org/:orgId()/cluster/categories/:categoryId()/edit",
    meta: editcMSXlHvqLSMeta || {},
    component: () => import("/usr/src/app/pages/org/[orgId]/cluster/categories/[categoryId]/edit.vue")
  },
  {
    name: "org-orgId-cluster-categories-create",
    path: "/org/:orgId()/cluster/categories/create",
    meta: createbfCAOgodVuMeta || {},
    component: () => import("/usr/src/app/pages/org/[orgId]/cluster/categories/create.vue")
  },
  {
    name: "org-orgId-cluster-categories",
    path: "/org/:orgId()/cluster/categories",
    meta: indexjFSspsvqjeMeta || {},
    component: () => import("/usr/src/app/pages/org/[orgId]/cluster/categories/index.vue")
  },
  {
    name: "org-orgId-cluster-create",
    path: "/org/:orgId()/cluster/create",
    meta: createbI1IJBHQSxMeta || {},
    component: () => import("/usr/src/app/pages/org/[orgId]/cluster/create.vue")
  },
  {
    name: "org-orgId-cluster",
    path: "/org/:orgId()/cluster",
    meta: indexDntc1NhH3MMeta || {},
    component: () => import("/usr/src/app/pages/org/[orgId]/cluster/index.vue")
  },
  {
    name: "org-orgId-edit",
    path: "/org/:orgId()/edit",
    meta: editNQxt6PXImZMeta || {},
    component: () => import("/usr/src/app/pages/org/[orgId]/edit.vue")
  },
  {
    name: "org-orgId-evaluation-evaluationId-edit",
    path: "/org/:orgId()/evaluation/:evaluationId()/edit",
    meta: edithUZbjfJGHuMeta || {},
    component: () => import("/usr/src/app/pages/org/[orgId]/evaluation/[evaluationId]/edit.vue")
  },
  {
    name: "org-orgId-evaluation-create",
    path: "/org/:orgId()/evaluation/create",
    meta: create2rwtlBAwzJMeta || {},
    component: () => import("/usr/src/app/pages/org/[orgId]/evaluation/create.vue")
  },
  {
    name: "org-orgId-evaluation",
    path: "/org/:orgId()/evaluation",
    meta: indexCxR8mtWfcoMeta || {},
    component: () => import("/usr/src/app/pages/org/[orgId]/evaluation/index.vue")
  },
  {
    name: "org-orgId-export",
    path: "/org/:orgId()/export",
    meta: indexwD09jQLrN6Meta || {},
    component: () => import("/usr/src/app/pages/org/[orgId]/export/index.vue")
  },
  {
    name: "org-orgId-home",
    path: "/org/:orgId()/home",
    meta: homeoNk4VAy7cqMeta || {},
    component: () => import("/usr/src/app/pages/org/[orgId]/home.vue")
  },
  {
    name: "org-orgId-project-projectId-edit",
    path: "/org/:orgId()/project/:projectId()/edit",
    meta: edit3JAFhhvJkQMeta || {},
    component: () => import("/usr/src/app/pages/org/[orgId]/project/[projectId]/edit.vue")
  },
  {
    name: "org-orgId-project-projectId-info",
    path: "/org/:orgId()/project/:projectId()/info",
    meta: infoNAFP06zvMUMeta || {},
    component: () => import("/usr/src/app/pages/org/[orgId]/project/[projectId]/info.vue")
  },
  {
    name: "org-orgId-project",
    path: "/org/:orgId()/project",
    meta: indexXMNanknYUAMeta || {},
    component: () => import("/usr/src/app/pages/org/[orgId]/project/index.vue")
  },
  {
    name: "org-orgId-user-userId-edit",
    path: "/org/:orgId()/user/:userId()/edit",
    meta: edit1JjAu1HHzhMeta || {},
    component: () => import("/usr/src/app/pages/org/[orgId]/user/[userId]/edit.vue")
  },
  {
    name: "org-orgId-user-userId-info",
    path: "/org/:orgId()/user/:userId()/info",
    meta: infonhdu4xF0eIMeta || {},
    component: () => import("/usr/src/app/pages/org/[orgId]/user/[userId]/info.vue")
  },
  {
    name: "org-orgId-user",
    path: "/org/:orgId()/user",
    meta: index7mXU2ApfXMMeta || {},
    component: () => import("/usr/src/app/pages/org/[orgId]/user/index.vue")
  },
  {
    name: "org",
    path: "/org",
    meta: indexazRby34V2JMeta || {},
    component: () => import("/usr/src/app/pages/org/index.vue")
  },
  {
    name: "reporting-reportingId-class",
    path: "/reporting/:reportingId()/class",
    meta: classAXlTwEqX1XMeta || {},
    component: () => import("/usr/src/app/pages/reporting/[reportingId]/class.vue")
  },
  {
    name: "reporting-reportingId",
    path: "/reporting/:reportingId()",
    meta: indexwXduZLa4SaMeta || {},
    component: () => import("/usr/src/app/pages/reporting/[reportingId]/index.vue")
  },
  {
    name: "reporting-reportingId-project",
    path: "/reporting/:reportingId()/project",
    meta: project8cHU0OTXNiMeta || {},
    component: () => import("/usr/src/app/pages/reporting/[reportingId]/project.vue")
  },
  {
    name: "reporting-reportingId-student",
    path: "/reporting/:reportingId()/student",
    meta: studentuBN5vVbrA2Meta || {},
    component: () => import("/usr/src/app/pages/reporting/[reportingId]/student.vue")
  },
  {
    name: "reporting-reportingId-teacher",
    path: "/reporting/:reportingId()/teacher",
    meta: teacherH2pbz1j3AgMeta || {},
    component: () => import("/usr/src/app/pages/reporting/[reportingId]/teacher.vue")
  },
  {
    name: "user-userId-edit",
    path: "/user/:userId()/edit",
    meta: editKdNTK2ZEUNMeta || {},
    component: () => import("/usr/src/app/pages/user/[userId]/edit.vue")
  },
  {
    name: "user-userId-info",
    path: "/user/:userId()/info",
    meta: info6XHwXaGi62Meta || {},
    component: () => import("/usr/src/app/pages/user/[userId]/info.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: index9wmPuxyvMJMeta || {},
    component: () => import("/usr/src/app/pages/user/index.vue")
  },
  {
    name: component_45stubnkSpTnJSJIMeta?.name,
    path: "/",
    component: component_45stubnkSpTnJSJI
  }
]