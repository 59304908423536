// plugins/i18n.ts
import { createI18n } from 'vue-i18n'
import { defineNuxtPlugin } from '#app'

const loadLocaleMessages = () => {
  const locales = import.meta.glob('../locales/*.json', { eager: true })
  const messages: { [key: string]: any } = {}

  for (const path in locales) {
    const matched = path.match(/\/([A-Za-z0-9-_]+)\.json$/i)
    if (matched && matched[1]) {
      const locale = matched[1].replace('_', '-')
      // @ts-expect-error
      messages[locale] = locales[path].default
    }
  }

  return messages
}

export default defineNuxtPlugin(({ vueApp }) => {
  const i18n = createI18n({
    locale: 'en-US',
    fallbackLocale: 'en-US',
    legacy: false,
    warnHtmlMessage: false,
    missingWarn: false,
    fallbackWarn: false,
    messages: loadLocaleMessages(),
  })

  vueApp.use(i18n)
})
